
//===================================
// REQUIRED
//===================================
import '../sass/app.scss';

//===================================
// OPTIONAL
//===================================
// require('./helpers/pixel_perfect');
// import flags from 'intl-tel-input/build/img/flags.png';
// import flags2x from 'intl-tel-input/build/img/flags@2x.png';

//===================================
// BASE
//===================================
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
window.intlTelInput = intlTelInput;

//===================================
// FASTSPRING
//===================================
// import * as jwt from 'jsonwebtoken';
// import csc from 'country-state-city';
// window.jwt = jwt;
// window.csc = csc;

//===================================
// UPDATE BILLING
//===================================
// import {popper} from "@popperjs/core";
// import {Tooltip} from "bootstrap";
// import {Alert} from "bootstrap";
// window.tooltip = Tooltip;

//===================================
// THEME
//===================================
// import { profanityAddedWords } from "./helpers/profanity-directory";
// const Profanity = require('profanity-js');
// let config = {
//     language: "en-us"
// };
// window.profanity = new Profanity('', config);
// window.profanity.addWords(...profanityAddedWords)